import React from "react"
import { graphql } from "gatsby"
//component
import Menu from "../components/Menu"
const Lieu = ({ data }) => {
  return (
    <div>
      <div className="navTree">
        <fieldset>
          <h2>Navigation</h2>
          <Menu />
        </fieldset>
      </div>
      <h1>
        {data.c.ville} ({data.c.pays})
      </h1>
    </div>
  )
}
export const query = graphql`
  query($id: String) {
    c: contentfulLocalite(id: { eq: $id }) {
      id
      ville
      pays
    }
  }
`

export default Lieu
